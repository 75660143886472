.aos-init {
  transition-duration: 0.2s !important; /* Faster animation duration */
  transition-timing-function: cubic-bezier(
    0.4,
    0,
    1,
    1
  ) !important; /* Aggressive easing */
}

.aos-animate {
  transition-duration: 0.2s !important;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

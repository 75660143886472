/* Add a class or use a more specific selector */
.slider-container .custom-slick-prev,
.slider-container .custom-slick-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 25px;
  color: white;
  cursor: pointer;
  background-color: #30698BB2;
  border-radius: 100%;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
}

.slider-container .custom-slick-prev {
  left: 10px;
}

.slider-container .custom-slick-next {
  right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .slider-container .custom-slick-prev {
    left: -50px;
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  .slider-container .custom-slick-next {
    right: -50px;
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .slider-container .custom-slick-prev {
    left: 2px;
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .slider-container .custom-slick-next {
    right: 2px;
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}

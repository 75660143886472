.trendBgImg1 {
  background-image: url("../images/TrendBg1.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}
.trendBgImg2 {
  background-image: url("../images/TrendBg2.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

.TrendTreatBg {
  background-image: url("../images/TrendTeamBgImg.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}
.Simg1 {
  visibility: visible;
  background-image: url("../images/Seminar1.png");
}
.Simg2 {
  visibility: visible;
  background-image: url("../images/Seminar2.png");
}
.Simg3 {
  visibility: visible;
  background-image: url("../images/Seminar3.png");
}
.ConsultancyBg {
  background-image: url("../images/ConsultancyBgImg.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}
.roundImg {
  position: absolute;
  height: 392px;
  /* width: 348px; */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.trendButton:hover {
  transition: ease-in;
  background-color: #37a4a5 !important;
  border: 2px solid #37a4a5 !important;
}

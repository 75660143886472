@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  /* Regular */
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  /* Bold */
}

.loginImg {
  background-image: url("../images/bgImg.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

.Button:hover {
  transition: ease-in;
  background-color: white !important;
  color: rgb(3, 3, 3) !important;
  border: 1px white !important;
  border-radius: 16px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .footContent {
    justify-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 425px) {
  .foot {
    justify-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .foot {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 1440px) {
  .head {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1441px) {
  .head {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

@media only screen and (min-width: 1324px) {
  .imghide {
    .img1 {
      background-image: url("../images/Img1.png");
      background-size: contain;
      background-repeat: no-repeat;

      width: 663.462px;
      height: 649px;
    }
  }
}

@media only screen and (min-width: 1324px) {
  .imghide {
    .img2 {
      background-image: url("../images/Img2.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 663.462px;
      height: 649px;
    }
  }
}

@media only screen and (min-width: 674px) {
  .mail {
    background-image: url("../images/MImg.png");
  }

  .utube {
    background-image: url("../images/YImg.png");
  }

  .footer-img-style {
    visibility: visible;
    background-size: contain;
    background-repeat: no-repeat;
    width: 265px;
    height: 253px;
  }
}

@media only screen and (max-width: 483px) {
  .img1 {
    visibility: visible;
    background-image: url("../images/Mob1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    width: 313px;
    height: 348px;
  }
}

@media only screen and (max-width: 483px) {
  .img2 {
    visibility: visible;
    background-image: url("../images/Mob2.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    width: 313px;
    height: 348px;
  }
}

@media only screen and (min-width: 482px) {
  .img1 {
    visibility: visible;
    background-image: url("../images/Mob1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    width: 459px;
    height: 499px;
  }
}

@media only screen and (min-width: 482px) {
  .img2 {
    visibility: visible;
    background-image: url("../images/Mob2.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    width: 459px;
    height: 499px;
  }
}

@media only screen and (min-width: 483px) {
  .mob {
    display: none !important;
  }
}

.portfolioImg1 {
  background-image: url("../images/Portfolio1.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

.portfolioImg2 {
  background-image: url("../images/Portfolio2.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .pmob {
    display: none !important;
  }
}

@media only screen and (max-width: 1440px) {
  .portfolioImg3 {
    background-image: none;

    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 769px) {
  .portfolioImg3 {
    visibility: visible;
    background-image: url("../images/Portfolio3.png");
  }
}

.portfolioImg4 {
  background-image: url("../images/Portfolio4.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

@media only screen and (max-width: 1440px) {
  .portfolioImg5 {
    background-image: none;
    background-color: #ebeef3;

    background-size: cover;
    background-repeat: no-repeat;

    height: 1000px !important;
  }
}

@media only screen and (min-width: 1441px) {
  .portfolioImg5 {
    visibility: visible;
    background-image: url("../images/Portfolio5.png");
  }
}

.polygon {
  background-image: url("../images/Polygon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 510px;
  width: 450px;
}

@media only screen and (max-width: 1024px) {
  .pImg1 {
    visibility: visible !important;

    height: 100px;
    width: 50;
  }
}

@media only screen and (max-width: 425px) {
  .mobPolygon {
    background-image: url("../images/Polygon.svg");
    background-size: contain;

    background-repeat: no-repeat;
    width: 510px;
    height: 375px;
    margin-left: 56px;
  }
}

@media only screen and (min-width: 426px) {
  .mobPolygon {
    background-image: url("../images/Polygon.svg");
    background-size: contain;

    background-repeat: no-repeat;
    width: 488px;
    height: 525px;
    margin-left: 199px;
    margin-right: 199px;
  }
}

@media only screen and (min-width: 1024px) {
  .polygon {
    background-image: url("../images/Polygon.svg");
    background-size: contain;
    height: 357px;
    width: 286px;

    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1218px) {
  .polygon {
    background-image: url("../images/Polygon.svg");
    background-size: contain;
    height: 410px;
    width: 350px;

    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1768px) {
  .polygon {
    background-image: url("../images/Polygon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 510px;
    width: 450px;
  }
}

.portfolioImg6 {
  background-image: url("../images/Portfolio6.png");
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
}

@media only screen and (max-width: 1440px) {
  .portfolioImg7 {
    background-image: none;
    background-color: #ebeef3;

    background-size: cover;
    background-repeat: no-repeat;
    height: 1500px !important;
  }
}

@media only screen and (min-width: 1441px) {
  .portfolioImg7 {
    visibility: visible;
    background-image: url("../images/Portfolio7.png");
  }
}

.Eimg1 {
  visibility: visible;
  background-image: url("../images/Ecrousal1.png");
}

.Eimg2 {
  visibility: visible;
  background-image: url("../images/Ecrousal2.png");
}

.Eimg3 {
  visibility: visible;
  background-image: url("../images/Ecrousal3.png");
}

@media only screen and (max-width: 425px) {
  .stylePolygonImg {
    background-size: contain;
    background-repeat: no-repeat;
    height: 354px !important;
    width: 354px !important;
    transition: transform 0.3s ease-in-out;
  }
}

@media only screen and (min-width: 426px) {
  .stylePolygonImg {
    background-size: contain;
    background-repeat: no-repeat;
    height: 330px !important;
    width: 335px !important;
    transition: transform 0.3s ease-in-out;
  }
}

@media only screen and (min-width: 769px) {
  .stylePolygonImg {
    background-size: contain;
    background-repeat: no-repeat;
    height: 426px !important;
    width: 426px !important;
    transition: transform 0.3s ease-in-out;
  }
}

@media only screen and (min-width: 1025px) {
  .stylePolygonImg {
    background-size: contain;
    background-repeat: no-repeat;
    height: 392px !important;
    width: 390px !important;
    margin-left: 11px;
    transition: transform 0.3s ease-in-out;
  }
}

@media only screen and (min-width: 1441px) {
  .stylePolygonImg {
    background-size: contain;
    background-repeat: no-repeat;
    height: 586px !important;
    width: 570px !important;
    margin-left: 70px;
    transition: transform 0.3s ease-in-out;
  }
}

/* Zoom-in effect on hover */
.stylePolygonImg:hover {
  transform: scale(1.02);
  /* Zoom in */
}

@media only screen and (min-width: 1441px) {
  .leftArrowImgStyle {
    background-size: contain;
    background-repeat: no-repeat;

    width: 100%;
    padding-left: 7%;
  }
}

@media only screen and (min-width: 1400px) {
  .leftArrowStyle {
    max-height: 800px;
    object-fit: fill;
  }
}
.portfolioButton:hover {
  transition: ease-in;

  background-color: #3778a5 !important;
  border: 2px solid #3778a5 !important;
}

/* Zoom-in effect on hover */
.zoom-in:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

/* Return to original state */
.zoom-in {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
